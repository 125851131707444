<template>
  <v-container fluid>
    <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "camera.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <span class="headline"></span>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('scanner.table_search')"
        single-line
        hide-details
      ></v-text-field>



      <v-dialog v-model="dialog" max-width="800px" persistent>
        <v-card>

          <v-card-title>
            <span class="headline">{{ $t('camera.title_edit') }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <!-- <v-layout wrap> -->

              <v-row dense>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Main Settings</v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.hostId" :label="$t('camera.host_id')" disabled></v-text-field>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field v-model="editedItem.uuid" :label="$t('camera.uuid')" disabled></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.propertyCode" :label="$t('camera.property_code')" disabled></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.equipmentName" :label="$t('camera.equipment_name')" disabled></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.localIp" :label="$t('camera.ip_address')" disabled></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field v-model="editedItem.username" :label="$t('camera.username')" ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field v-model="editedItem.password" :label="$t('camera.password')" ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-switch
                            color="primary"
                            v-model="editedItem.isRecording"
                            label="recording"
                          ></v-switch>
                        </v-col>
                        <v-col cols="3">
                          <v-switch
                            color="primary"
                            v-model="editedItem.isDetecting"
                            label="detecting"
                          ></v-switch>
                        </v-col>
                      </v-row>                    
                    </v-card-text>


                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card>
                    <v-card-title>RTSP Settings</v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.rtsp.port" :label="$t('camera.rtsp_port')" ></v-text-field>
                        </v-col>
                        <v-col cols="8">
                          <v-text-field v-model="editedItem.rtsp.path" :label="$t('camera.rtsp_path')" ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.rtsp.codec" :label="$t('camera.rtsp_codec')" ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.rtsp.framerate" :label="$t('camera.rtsp_framerate')" ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card>
                    <v-card-title>ONVIF Settings</v-card-title>

                    <v-card-text>
                      <v-row>
                        <v-col cols="4">
                          <v-text-field v-model="editedItem.onvif.port" :label="$t('camera.onvif_port')" ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                          <v-switch
                            color="primary"
                            v-model="editedItem.onvif.isSubscription"
                            label="subscription"
                          ></v-switch>
                        </v-col>
                        <v-col cols="4">
                          <v-switch
                            color="primary"
                            v-model="editedItem.onvif.isPullpoint"
                            label="pullpoint"
                          ></v-switch>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>

              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green lighten-1" text dark @click="close">{{ $t('listing.btn_cancel') }}</v-btn>
            <v-btn color="green lighten-1" text dark @click="save">{{ $t('listing.btn_save') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>


    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="computedCameras"
      :search="search"
      class="elevation-1"
    >
      <template v-slot:item.sinceLastHeartbeat="{ item }" >
        <v-chip 
          v-if="item.sinceLastHeartbeat"
          :color="getColor(item.lastUpdateOn, item.isPlaying)"
          dark
        >
          {{ item.sinceLastHeartbeat }}
        </v-chip>        
      </template>
      <template v-slot:item.edit="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="editItem(item)"
        >
          edit
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 28px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'  
import { CallDialog, AppSyncSubscriber } from '@/utils'
import store from '@/store'

import * as moment from 'moment'
import 'moment-duration-format'

export default {
  data: () => ({
    search: '',
    dialog: false,
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 10,
      sortBy: 'lastUpdateOn',
      totalItems: 0,
      rowsPerPageItems: [10,10,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]
    },
    editedIndex: -1,
    editedItem: {
      hostId: '',
      uuid: '',
      propertyCode: '',
      category: '',
      equipmentId: '',
      equipmentName: '',
      localIp: '',
      username: '',
      password: '',
      isDetecting: false,
      isRecording: false,
      rtsp: {
        port: 0,
        path: '',
        codec: '',
        framerate: 0
      },
      onvif: {
        port: 0,
        isSubscription: false,
        isPullpoint: false
      }
    },
    defaultItem: {
      hostId: '',
      uuid: '',
      propertyCode: '',
      category: '',
      equipmentId: '',
      equipmentName: '',
      localIp: '',
      username: '',
      password: '',
      isDetecting: false,
      isRecording: false,
      rtsp: {
        port: 0,
        path: '',
        codec: '',
        framerate: 0
      },
      onvif: {
        port: 0,
        isSubscription: false,
        isPullpoint: false
      }
    },
  }),
  computed: {
    headers () {
      return [
        { text: this.$t('scanner.table_edit'), value: 'edit', align: 'left', sortable: false },
        { text: this.$t('scanner.table_property_code'), value: 'propertyCode', align: 'left', sortable: false},
        { text: this.$t('scanner.table_terminal_name'), value: 'equipmentName', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_terminal_key'), value: 'equipmentId', align: 'left', sortable: false},
        { text: this.$t('scanner.table_ip'), value: 'localIp', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_username'), value: 'username', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_password'), value: 'password', align: 'left', sortable: false},
        { text: this.$t('scanner.table_detect'), value: 'isDetecting', align: 'left', sortable: false},
        { text: this.$t('scanner.table_record'), value: 'isRecording', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_rtsp'), value: 'rtsp.port', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_rtsp'), value: 'rtsp.path', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_rtsp'), value: 'rtsp.codec', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_rtsp'), value: 'rtsp.framerate', align: 'left', sortable: false},
        // { text: this.$t('scanner.table_onvif'), value: 'onvif.port', align: 'left', sortable: false},
        { text: this.$t('scanner.table_last_update'), value: 'lastUpdateOn', align: 'left', sortable: true},
        { text: this.$t('scanner.table_last_heartbeat'), value: 'sinceLastHeartbeat', align: 'left', sortable: false}
      ]
    },
    computedCameras() {
      const results = this.$store.state.scanner.cameras.map(camera => {
        const newCamera = Object.assign({}, camera)
        newCamera.sinceLastHeartbeat = this.getDurationText(newCamera.lastUpdateOn, newCamera.isPlaying)
        return newCamera
      })
      return results
    },
    ...mapState({
      cameras: state => state.scanner.cameras,
      nowTime: state => state.scanner.nowTime
    }),
  },
  async beforeRouteEnter (to, from, next) {
    const error = await store.dispatch('scanner/loadCameras').catch(error => {      
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  created () {
    this.startTimer()
  },
  methods: {
    getDurationText (lastUpdateOn, isPlaying) {
      // if (isPlaying) {
      //   if (lastUpdateOn) {
      //     const durationMilliseconds = (new Date(this.nowTime)).getTime() - Date.parse(lastUpdateOn)
      //     return moment.duration(durationMilliseconds).format("d [days] hh:mm:ss[s]")
      //   }       
      // } else {
      //   return ''
      // }
      if (lastUpdateOn) {
        const durationMilliseconds = (new Date(this.nowTime)).getTime() - Date.parse(lastUpdateOn)
        return moment.duration(durationMilliseconds).format("d [days] hh:mm:ss[s]")
      }   
    },
    getDuration (lastUpdateOn) {
      if (lastUpdateOn) {
        const durationMilliseconds = (new Date(this.nowTime)).getTime() - Date.parse(lastUpdateOn)
        return durationMilliseconds
      }
    },
    getColor (lastUpdateOn, isPlaying) {
      const diff = this.getDuration(lastUpdateOn, isPlaying)

      if (isPlaying) {
        if (diff < 600000) {
          return 'green'
        } else {
          return 'orange'
        }
      }

      return 'red'

    },
    async editItem (item) {
      this.editedIndex = this.computedCameras.indexOf(item)
      this.editedItem = Object.assign({}, this.cameras[this.editedIndex])

      this.dialog = true
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 10)
    },
    async save () {
      console.log('save', this.editedIndex)
      if (this.editedIndex > -1) {
        const error = await this.updateCamera({
          params: this.editedItem,
          index: this.editedIndex
        }).catch(e => {
          console.error('updateCamera', e)
          return e
        })

        if (error) {
          CallDialog(error)  
        } else {
          this.close()
        }
      }
    },
    ...mapActions({
      startTimer: 'scanner/startTimer',
      updateCamera: 'scanner/updateCamera',
    })
  }
}
</script>